import * as React from 'react';
import PropTypes from 'prop-types';
import ContentValidationPage from './ContentValidationPage'
import axios from 'axios'
import { getAuthToken } from '../utils/auth'
import { useSnackbar } from 'notistack';

export default function ValidationPage(props) {
  const { enqueueSnackbar } = useSnackbar();
  
  const doAction = async (row, status, callBack) => {
    if(status === 'valid') {
      await validateJudgment(row);
    } else {
      await deleteSanction(row);
    }
    callBack();
  }
  
  const validateJudgment = (row) => {
    return axios.put(process.env.REACT_APP_API_URL + '/judgment/validate/' + row._id,
      { row }, { headers: { "Authorization": getAuthToken() } })
      .then(async response => {
        props.updateJudgments(response.data);
        enqueueSnackbar('Sanction validée !', {variant: 'success', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
      })
      .catch(error => {
        console.error(error);
        if(error.response.status === 401) {
          enqueueSnackbar("Le token d'authentification a expiré. Veuillez vous reconnecter.", {variant: 'error', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          props.logout();
        }
      });
  }
  
  const deleteSanction = (row) => {
    return axios.put(process.env.REACT_APP_API_URL + '/judgment/invalidate/' + row._id,
      { row }, { headers: { "Authorization": getAuthToken() } })
      .then(async response => {
        props.updateJudgments(response.data);
        enqueueSnackbar('Sanction refusée !', {variant: 'error', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
      })
      .catch(error => {
        console.error(error);
        if(error.response.status === 401) {
          enqueueSnackbar("Le token d'authentification a expiré. Veuillez vous reconnecter.", {variant: 'error', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          props.logout();
        }
      });
  }
  
  return (
      <ContentValidationPage judgments={props.judgments} doAction={doAction} />
  );
}

ValidationPage.propTypes = {
  judgments: PropTypes.array,
  updateJudgments: PropTypes.func,
  logout: PropTypes.func,
}
ValidationPage.defaultProps = {
  judgments: [],
  updateJudgments: () => console.log("updateJudgments func"),
  logout: () => console.log("logout func"),
}
