import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import GavelIcon from '@mui/icons-material/Gavel';
import ModalAddSanction from './ModalAddSanction'
import PropTypes from 'prop-types';

class HeaderCagnotte extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddSanctionModalOpen: false,
    }
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  handleOpen = () => {
    this.setState({
      isAddSanctionModalOpen: true
    })
  }
  
  handleClose = () => {
    this.setState({
      isAddSanctionModalOpen: false
    })
  }
  
  render() {
    const {cagnotte} = this.props;
    let urlImage;
    if(cagnotte <= 30) {
      urlImage = "https://media.tenor.com/_IT4iiUqkw4AAAAC/homer-simpson-les-simpson.gif";
    } else if(cagnotte > 30 && cagnotte <= 100) {
      urlImage = "https://media0.giphy.com/media/14SAx6S02Io1ThOlOY/giphy.gif";
    } else {
      urlImage = "https://media.tenor.com/99CVARN9faoAAAAM/picsou-scrooge.gif";
    }
    return (
      <div style={{
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 35,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        <div>
          <h2 style={{ textAlign: 'center' }}>Cagnotte globale</h2>
          <Card sx={{
            minWidth: 300 ,
            display: 'flex',
            height: 150,
            alignItems: 'center',
            justifyContent: 'flex-end',
            boxShadow: '0 2px 1px 0 rgb(0 0 0 / 10%)',
            borderRadius: 5,
            marginBottom: 5,
            marginLeft: 'auto',
            marginRight: 'auto',
            color: '#fff',
            fontWeight: 'bold',
            backgroundImage: "url('" + urlImage + "')",
            backgroundPosition: 'center', /* Center the image */
            backgroundRepeat: 'no-repeat', /* Do not repeat the image */
            backgroundSize: 'cover'/* Resize the background image to cover the entire container */
          }}>
            <CardContent>
              <Typography component="div" variant="h4" style={{ textAlign: 'right' }}>
                { cagnotte } €
              </Typography>
            </CardContent>
          </Card>
        </div>
        <Button variant="contained" startIcon={<GavelIcon/>} onClick={this.handleOpen}>Ajouter une sanction</Button>
        <ModalAddSanction open={this.state.isAddSanctionModalOpen} refreshJudgments={this.props.refreshJudgments} handleClose={this.handleClose} sanctions={this.props.sanctions} players={this.props.players} categories={this.props.categories}/>
      </div>
    );
  }
}

HeaderCagnotte.propTypes = {
  cagnotte: PropTypes.string,
  sanctions: PropTypes.array,
  categories: PropTypes.array,
  players: PropTypes.array,
  refreshJudgments: PropTypes.func,
}
HeaderCagnotte.defaultProps = {
  cagnotte: 0,
  sanctions: [],
  categories: [],
  players: [],
  refreshJudgments: () => {console.log('refreshJudgments func')},
}

export default HeaderCagnotte;
