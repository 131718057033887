import React from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { login } from '../utils/auth'
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

class ModalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      pwd: null,
      loading: false,
      error: false
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      error: false
    })
    this.authUser(this.state.username, this.state.pwd)
  };
  
  authUser = async (username, pwd) => {
    axios.post(process.env.REACT_APP_API_URL + '/login',
      {
        username: username,
        password: pwd
      })
      .then(async response => {
        let {user, token} = await response.data
        user = JSON.stringify(user);
        await login({user, token})
        this.setState({
          loading: false,
          error: false
        })
        this.props.handleClose(true);
      })
      .catch(error => {
        console.error(error);
        this.setState({
          loading: false,
          error: true
        })
      });
  }
  
  handleCloseModal = (_, reason) => {
    if (reason !== "backdropClick") {
      this.props.handleClose();
    }
  }
  
  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    
    return (
      <div>
        <Modal
          className={"loginModal modal"}
          keepMounted
          open={this.props.open}
          onClose={this.handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          autoFocus={false}
        >
          <Box
            sx={style}
          >
            <Typography component="h2" variant="h5">
              Authentification
            </Typography>
            <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Identifiant"
                name="username"
                autoComplete="username"
                autoFocus={true}
                onChange={e => this.setState({username: e.target.value})}
              
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => this.setState({pwd: e.target.value})}
              />
              <div className={"buttons"}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={this.handleCloseModal}
                  disabled={this.state.loading}
                >
                  Fermer
                </Button>
                <LoadingButton
                  loading={this.state.loading}
                  variant="contained"
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                  type={'submit'}
                >
                  Connexion
                </LoadingButton>
              </div>
            </Box>
          </Box>
        </Modal>
        <Snackbar open={this.state.error} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => this.setState({error: false})}>
          <Alert severity="error">Connexion refusée !</Alert>
        </Snackbar>
      </div>
    
    );
  }
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ModalLoginForm;

