import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Person from '@mui/icons-material/Person';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';

class ListDettesJoueurs extends React.Component {
  
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  }
  
  renderRow = (props) => {
    const { index, style } = props;
    const rows = [...this.props.players].sort((a, b) => {
      const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
      const nameA2 = a.last_name.toUpperCase(); // ignore upper and lowercase
      const nameB2 = b.last_name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      if (nameA2 < nameB2) {
        return -1;
      }
      if (nameA2 > nameB2) {
        return 1;
      }
      return 0;
    });
    const player = rows[index];
    let sommePlayer = 0;
    
    this.props.judgments.forEach(j => {
      if(player._id === j.player._id && j.status === 'valid') {
        sommePlayer += parseFloat(j.sanction.price);
      }
    })
    return (
      <ListItem secondaryAction={sommePlayer.toFixed(2) + "€"} style={style} key={index} component="div" disablePadding>
        <ListItemAvatar>
          <Avatar>
            <Person />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={player.first_name + ' ' + player.last_name}
        />
      </ListItem>
    );
  }
  
  render() {
    if(this.props.players.length > 0) {
      return (
        <FixedSizeList height={600} itemSize={72} itemCount={this.props.players.length} overscanCount={5}>
          {this.renderRow}
        </FixedSizeList>
      );
    } else {
      return (
        <p>Aucun joueur pour le moment...</p>
      )
    }
  }
}

ListDettesJoueurs.propTypes = {
  players : PropTypes.array
}
ListDettesJoueurs.defaultProps = {
  players: []
}

export default ListDettesJoueurs;

