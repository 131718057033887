import cookie from 'js-cookie'

export const login = ({ user, token }) => {
    const expiryTime = new Date(new Date().getTime() + 60 * 60 * 3000);
    cookie.set('token', token, { expires: expiryTime })
    cookie.set('user', user, { expires: expiryTime })
}

export const getAuthToken =() => {
    return cookie.get('token');
}

export const getAuthUser =() => {
    return cookie.get('user');
}

export const isValidToken =() => {
    return true;
}

export const logout = () => {
    cookie.remove('token')
    cookie.remove('user')
    // to support logging out from all windows
    window.localStorage.setItem('logout', Date.now())
}

