import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import DoneIcon from '@mui/icons-material/Done';
//import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { green/*, red*/, grey } from '@mui/material/colors';
import { FixedSizeList } from 'react-window'

class ListSanctionCagnotte extends React.Component {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
  
  }
  
  renderRow = (props) => {
    const { index, style } = props;
    const judgmentsToValdidateOrValid = this.props.judgments.filter(j => j.status !== 'invalid' && j.sanction)
    const rows = [...judgmentsToValdidateOrValid].sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    const judgment = rows[index];
    
    let status = (
      <Avatar sx={{ bgcolor: grey[500] }}>
        <HourglassEmptyIcon/>
      </Avatar>
    )
  
    if(judgment.status === 'valid') {
      status = (
        <Avatar sx={{ bgcolor: green[500] }}>
          <DoneIcon/>
        </Avatar>
      )
    } /*else if(judgment.status === 'invalid') {
      status = (
        <Avatar sx={{ bgcolor: red[500] }}>
          <CloseIcon/>
        </Avatar>
      )
    }*/
    
    return (
      <ListItem secondaryAction={judgment.sanction.price.toFixed(2) + "€"} style={style} key={index} component="div" disablePadding>
        <ListItemAvatar>
          {status}
        </ListItemAvatar>
        <ListItemText
          primary={judgment.player.first_name + " " + judgment.player.last_name}
          secondary={judgment.sanction.label}
        />
      </ListItem>
    );
  }
  render() {
    const judgmentsToValdidateOrValid = this.props.judgments.filter(j => j.status !== 'invalid' && j.sanction)
    if(judgmentsToValdidateOrValid.length > 0) {
      return (
        <FixedSizeList height={600} itemSize={72} itemCount={judgmentsToValdidateOrValid.length} overscanCount={5}>
          {this.renderRow}
        </FixedSizeList>
      );
    } else {
      return (
        <p>Aucune sanction pour le moment...</p>
      )
    }
  }
}

ListSanctionCagnotte.propTypes = {
  judgments : PropTypes.array
}
ListSanctionCagnotte.defaultProps = {
  judgments: []
}

export default ListSanctionCagnotte;
