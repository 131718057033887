import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Modal from '@mui/material/Modal'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import { getAuthToken } from '../utils/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

class SanctionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sanctionSelected : null,
      formVisible : false,
      label : "",
      price : "",
      category: "",
      error: false,
      authError: false,
      loading: false,
      confirmOpen: false,
    }
    this.openModalEmpty = this.openModalEmpty.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.deleteSanction = this.deleteSanction.bind(this);
    this.openModalWithSanction = this.openModalWithSanction.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.dataTreatment = this.dataTreatment.bind(this);
    
  }
  
  openModalEmpty = () => {
    this.setState({
      sanctionSelected : null,
      formVisible : true,
      label : "",
      price : "",
      category: "",
      error: false,
      authError: false,
      loading: false,
      confirmOpen: false,
    })
  }
  
  closeModal = () => {
    this.setState({
      sanctionSelected : null,
      formVisible : false,
      error: false,
      authError: false,
      loading: false,
      label : "",
      price : "",
      category: "",
      confirmOpen: false,
    })
  }
  
  openDeleteDialog = (sanction) => {
    this.setState({
      sanctionSelected: sanction,
      confirmOpen: true,
    })
  }
  
  closeDeleteDialog = () => {
    this.setState({
      sanctionSelected: null,
      confirmOpen: false,
    })
  }
  
  deleteSanction = () => {
    this.setState({
      loading: true,
      authError: false,
      error: false,
    })
    axios.delete(process.env.REACT_APP_API_URL + '/sanction/' + this.state.sanctionSelected._id,
      { headers: { "Authorization": getAuthToken() } })
      .then(async response => {
        this.setState({
          loading: false,
        })
        this.closeDeleteDialog();
        this.props.refreshSanctions();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          loading: false,
          error: error.response.status !== 401,
          authError: error.response.status === 401,
        })
      });
  }
  
  openModalWithSanction = (sanction) => {
    this.setState({
      sanctionSelected : sanction,
      formVisible : true,
      error: false,
      authError: false,
      loading: false,
      label : sanction.label,
      price : sanction.price,
      category: sanction.category,
      confirmOpen: false,
    })
  }
  
  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      error: false,
      authError: false,
    });
    
    
    if(this.state.label === null || this.state.label === "" ||
      this.state.price === null || parseFloat(this.state.price) <= 0 ||
      this.state.category === null || this.state.category === ""
    ) {
      this.setState({
        loading: false,
        error: true
      });
    } else {
      if(this.state.sanctionSelected) {
        axios.put(process.env.REACT_APP_API_URL + '/sanction/' + this.state.sanctionSelected._id,
          {
            label: this.state.label,
            price: this.state.price,
            category: this.state.category
          }, { headers: { "Authorization": getAuthToken() } })
          .then(async response => {
            let sanction = await response.data
            this.dataTreatment(sanction);
          })
          .catch(error => {
            console.error(error);
            this.setState({
              loading: false,
              error: error.response.status !== 401,
              authError: error.response.status === 401,
            });
          });
      } else {
        axios.post(process.env.REACT_APP_API_URL + '/sanction',
          {
            label: this.state.label,
            price: this.state.price,
            category: this.state.category
          }, { headers: { "Authorization": getAuthToken() } })
          .then(async response => {
            let sanction = await response.data
            this.dataTreatment(sanction);
          })
          .catch(error => {
            console.error(error);
            this.setState({
              loading: false,
              error: error.response.status !== 401,
              authError: error.response.status === 401,
            })
          });
      }
    }
  };
  
  dataTreatment = (sanction) => {
    this.setState({
      loading: false,
      error: false,
      authError: false,
      confirmOpen: false,
    })
    this.props.updateSanctions(sanction);
    this.closeModal();
  }
  
  render() {
    const rows = [...this.props.sanctions].sort((a, b) => {
      const labelA = a.label.toUpperCase(); // ignore upper and lowercase
      const labelB = b.label.toUpperCase(); // ignore upper and lowercase
      const priceA = a.price; // ignore upper and lowercase
      const priceB = b.price; // ignore upper and lowercase
      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      if (priceA < priceB) {
        return -1;
      }
      if (priceA > priceB) {
        return 1;
      }
      return 0;
    });
    const content = rows.length > 0 ? (
      rows.map((row, index) => {
        const labelCategory = this.props.categories.find(e => e.key === row.category).label
        return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={"rowTableSanctions" + index}>
          <TableCell>
            {row.label}
          </TableCell>
          <TableCell>
            {row.price.toFixed(2)} €
          </TableCell>
          <TableCell>
            {labelCategory}
          </TableCell>
          <TableCell>
            <IconButton aria-label="edit" onClick={() => this.openModalWithSanction(row)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" color="error" onClick={() => this.openDeleteDialog(row)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      )})
    ) : (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          Aucune sanction pour le moment
        </TableCell>
      </TableRow>
    );
    const categoriesContent = this.props.categories.map((row, index) => {
      return (<MenuItem key={'category' + index} value={row.key}>{row.label}</MenuItem>)
    });
    
    const modalTitle = this.state.sanctionSelected ? "Modification de la sanction" : "Ajouter une sanction";
    const errorMsg = this.state.error ? "Tous les champs sont obligatoires. Le prix doit être supérieur à 0." : (this.state.authError ? "Le token d'authentification a expiré. Veuillez vous reconnecter." : "");
    
    const styleModal = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    return (
      <div style={{
        width: '100%',
        marginBottom: 30
      }}>
        
        <h2 style={{ textAlign: 'center' }}>Gestion des sanctions</h2>
        <Button variant={"contained"} style={{ textAlign: 'right', margin: '10px auto 10px auto', float: 'right'}} onClick={this.openModalEmpty} startIcon={<AddIcon />}>Ajouter une sanction</Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" >
            <TableHead>
              <TableRow>
                <TableCell>Libellé</TableCell>
                <TableCell>Montant</TableCell>
                <TableCell>Catégorie</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          className={"formModal modal"}
          keepMounted
          open={this.state.formVisible}
          onClose={this.closeModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={styleModal}
          >
            <Typography component="h1" variant="h5">
              {modalTitle}
            </Typography>
            <Box component="form" onSubmit={this.handleSubmitForm} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="label"
                label="Libellé"
                name="label"
                autoFocus
                value={this.state.label}
                onChange={e => this.setState({label: e.target.value})}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="price"
                label="Prix"
                id="price"
                type={"number"}
                value={this.state.price}
                onChange={e => this.setState({price: e.target.value})}
              />
              <FormControl style={{ minWidth: 250, marginTop: 10}}>
                <InputLabel id="category-label">Catégorie *</InputLabel>
                <Select
                  labelId="category-label"
                  id="category"
                  value={this.state.category}
                  label="Catégorie"
                  autoWidth
                  onChange={e => this.setState({category: e.target.value})}
                >
                  {categoriesContent}
                </Select>
              </FormControl>
              <div className={"buttons"}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={this.closeModal}
                  sx={{ mt: 3, mb: 2 }}
                  disabled={this.state.loading}
                >
                  Fermer
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={this.state.loading}
                >
                  Valider
                </LoadingButton>
              </div>
            </Box>
          </Box>
        </Modal>
        <Dialog
          open={this.state.confirmOpen}
          onClose={this.closeDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation de la suppression"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              La suppression de cette sanction entrainera la suppression dans la cagnotte de toutes les fois où elle a été déclarée. Êtes -vous certain de vouloir la supprimer ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDeleteDialog} disabled={this.state.loading}>Annuler</Button>
            <LoadingButton
              loading={this.state.loading}
              onClick={this.deleteSanction}
              autoFocus
            >
              Supprimer
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Snackbar open={this.state.error || this.state.authError} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => {
          if(this.state.authError) {
            this.setState({ error: false, authError: false })
            this.props.logout();
          } else {
            this.setState({ error: false, authError: false })
          }
        }}>
          <Alert severity="error">{errorMsg}</Alert>
        </Snackbar>
      </div>
    );
  }
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

SanctionsPage.propTypes = {
  sanctions: PropTypes.array,
  categories: PropTypes.array,
  updateSanctions: PropTypes.func,
  refreshSanctions: PropTypes.func,
  logout: PropTypes.func,
}
SanctionsPage.defaultProps = {
  sanctions: [],
  categories: [],
  updateSanctions:  () => console.log("UpdateSanctions func"),
  refreshSanctions:  () => console.log("refreshSanctions func"),
  logout:  () => console.log("logout func")
}

export default SanctionsPage;
