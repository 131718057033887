import './App.css';
import axios from 'axios'
import HeaderCagnotte from './components/HeaderCagnotte';
import DataTab from './components/DataTab';
import FooterNavigation from './components/FooterNavigation';
import Container from '@mui/material/Container';
import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ModalLoginForm from './components/LoginForm'
import { getAuthToken, logout, isValidToken } from './utils/auth'
import UsersPage from './components/UsersPage'
import SanctionsPage from './components/SanctionsPage'
import ValidationPage from './components/ValidationPage'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import blue from '@mui/material/colors/blue';
import red from '@mui/material/colors/red';
import yellow from '@mui/material/colors/yellow';
import grey from '@mui/material/colors/grey';
import green from '@mui/material/colors/green';
import { SnackbarProvider } from 'notistack';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginModalOpen: false,
      sanctions: [],
      players: [],
      judgments: [],
      categories: [],
      isContentLoaded: false,
      auth: false,
      page: 'home'
    }
    this.handleOpenLoginForm = this.handleOpenLoginForm.bind(this);
    this.handleCloseLoginForm = this.handleCloseLoginForm.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.logoutApp = this.logoutApp.bind(this);
    this.updatePlayers = this.updatePlayers.bind(this);
    this.updateSanctions = this.updateSanctions.bind(this);
    this.updateJudgments = this.updateJudgments.bind(this);
    this.refreshPlayers = this.refreshPlayers.bind(this);
    this.refreshSanctions = this.refreshSanctions.bind(this);
    this.refreshJudgments = this.refreshJudgments.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.refreshAll = this.refreshAll.bind(this);
  }
  
  componentDidMount() {
    this.setState({
      isContentLoaded: false,
      auth: (getAuthToken() && isValidToken())
    });
    this.refreshAll();
  }
  
  refreshAll = async () => {
    await Promise.all([this.refreshSanctions(), this.refreshPlayers(), this.refreshJudgments(), this.getCategories()])
    this.setState({
      isContentLoaded: true,
      auth: (getAuthToken() && isValidToken())
    })
  }
  
  getCategories = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/categories', {})
      .then(async response => {
        this.setState({
          categories: response.data.sort((a,b) => { return a.order - b.order;})
        })
      })
      .catch(error => {
        console.error(error);
      })
  }
  
  refreshSanctions = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/sanctions', {})
      .then(async response => {
        this.setState({
          sanctions: response.data
        })
      })
      .catch(error => {
        console.error(error);
      })
  }
  
  refreshPlayers = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/players', {})
      .then(async response => {
        this.setState({
          players: response.data
        })
      })
      .catch(error => {
        console.error(error);
      })
  }
  
  refreshJudgments = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/judgments', {})
      .then(async response => {
        this.setState({
          judgments: response.data
        })
      })
      .catch(error => {
        console.error(error);
      })
  }
  
  updateSanctions = sanction => {
    const index = this.state.sanctions.findIndex( e => e._id === sanction._id);
    if(index !== -1) {
      const newTab = [...this.state.sanctions];
      newTab[index] = sanction;
      this.setState({
        sanctions: [...newTab]
      });
    } else {
      const newTab = [...this.state.sanctions];
      newTab.push(sanction);
      this.setState({
        sanctions: newTab
      });
    }
  }
  
  updatePlayers = player => {
    const index = this.state.players.findIndex( e => e._id === player._id);
    if(index !== -1) {
      const newPlayers = [...this.state.players];
      newPlayers[index] = player;
      this.setState({
        players: [...newPlayers]
      });
    } else {
      const newPlayers = [...this.state.players];
      newPlayers.push(player);
      this.setState({
        players: newPlayers
      });
    }
  }
  
  updateJudgments = judgment => {
    const index = this.state.judgments.findIndex( e => e._id === judgment._id);
    if(index !== -1) {
      const newTab = [...this.state.judgments];
      newTab[index] = judgment;
      this.setState({
        judgments: [...newTab]
      });
    }
  }
  
  handleOpenLoginForm = () => {
    this.setState({
      isLoginModalOpen: true
    })
  }
  
  handleCloseLoginForm = (isAuth= false) => {
    this.setState({
      isLoginModalOpen: false,
      auth: isAuth
    })
  }
  
  handleChangePage = (page= "home") => {
    this.setState({
      page: page,
    })
  }
  
  calcCagnotte = () => {
    let tot = 0;
    this.state.judgments.forEach(j => {
      if(j.status === 'valid' && j.sanction)
        tot += parseFloat(j.sanction.price);
    })
    return tot.toFixed(2);
  }
  
  logoutApp = () => {
    logout();
    this.setState({
      auth: false,
      page: 'home'
    })
  }
  
  render() {
    const adminButton = this.state.auth ? <Button style={{float: 'right'}} color="inherit" onClick={this.logoutApp}>Déconnexion</Button> : <Button style={{float: 'right'}} color="inherit" onClick={this.handleOpenLoginForm}>Administration</Button>
    const footerNav = this.state.auth ? <FooterNavigation changePage={this.handleChangePage}/> : null;
    const containerStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      background: "transparent",
      flexWrap: 'wrap',
      border: 'none',
      paddingTop: 50,
    };
    let pageContent = null;
    const theme = createTheme({
      palette: {
        primary: blue,
        secondary: grey,
        error: red,
        warning: yellow,
        info: grey,
        success: green,
      },
    });
    switch (this.state.page) {
      case 'home':
        pageContent = (
          <Container maxWidth="lg" style={containerStyle}>
            <HeaderCagnotte cagnotte={this.calcCagnotte()} sanctions={this.state.sanctions} players={this.state.players} categories={this.state.categories} refreshJudgments={this.refreshJudgments}/>
            <DataTab judgments={this.state.judgments} players={this.state.players}/>
            <ModalLoginForm open={this.state.isLoginModalOpen} handleClose={this.handleCloseLoginForm}/>
          </Container>
        );
        break;
      case 'validation':
        pageContent = (
          <Container maxWidth="lg" style={containerStyle}>
            <ValidationPage judgments={this.state.judgments} updateJudgments={this.updateJudgments} logout={this.logoutApp}/>
          </Container>
        );
        break;
      case 'sanctions':
        pageContent = (
          <Container maxWidth="lg" style={containerStyle}>
            <SanctionsPage sanctions={this.state.sanctions} categories={this.state.categories} updateSanctions={this.updateSanctions} refreshSanctions={this.refreshAll} logout={this.logoutApp}/>
          </Container>
        );
        break;
      case 'players':
        pageContent = (
          <Container maxWidth="lg" style={containerStyle}>
            <UsersPage players={this.state.players} updatePlayers={this.updatePlayers} refreshPlayers={this.refreshAll} logout={this.logoutApp}/>
          </Container>
        );
        break;
      default:
        break;
    }
    
    return (
      <SnackbarProvider maxSnack={5}>
        <div id={'loading-screen'} style={{display: this.state.isContentLoaded ? 'none' : 'flex' }}>
          <img src="https://i.pinimg.com/originals/1a/3b/d2/1a3bd2b9bb77b8297ee5a1a693ae9d29.gif" alt="Loading"/>
          <p>Chargement des données</p>
        </div>
      <div style={{ background: "#f5f7fa", }}>
        <AppBar position="sticky">
          <Container maxWidth="lg">
            <Toolbar style={{justifyContent: 'space-between'}}>
              <img
                src={"/logo.png"}
                alt={"logo caisse noire"} style={{ width: 64, padding: 10 }}/>
              &nbsp;&nbsp;&nbsp;
              <Typography
                variant="h6"
                noWrap
                component="h1"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              >
                Caisse noire - Séniors Garçons
              </Typography>
              {adminButton}
            </Toolbar>
          </Container>
        </AppBar>
        <ThemeProvider theme={theme}>
          {pageContent}
        </ThemeProvider>
        {footerNav}
      </div>
      </SnackbarProvider>
    );
  }
}

export default App;
