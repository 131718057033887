import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ListDettesJoueurs from './ListDettesJoueurs'
import ListSanctionCagnotte from './ListSanctionCagnotte'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views';

class DataTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }
  
  handleChange = (event, newValue) => {
    this.setState({
      index : newValue
    })
  };
  
  handleChangeIndex = (index) => {
    this.setState({
      index : index
    })
  };
  
  render() {
    return (
      <div  style={{
        width: "650px",
        background: '#fff',
        boxShadow: '0 2px 1px 0 rgb(0 0 0 / 10%)',
        borderRadius: 5,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        <Tabs value={this.state.index} onChange={this.handleChange} centered textColor={"primary"}>
          <Tab label="Sanctions" />
          <Tab label="Dettes par joueur" />
        </Tabs>
        <SwipeableViews axis={'x-reverse'}
                        index={this.state.index}
                        onChangeIndex={this.handleChangeIndex}>
          <div
            role="tabpanel"
            hidden={this.state.index !== 0}
            id={`simple-tabpanel-0`}
            aria-labelledby={`simple-tab-0`}
          >
            {this.state.index === 0 && (
              <Box sx={{ p: 3 }}>
                <ListSanctionCagnotte judgments={this.props.judgments} />
              </Box>
            )}
          </div>
          <div
            role="tabpanel"
            hidden={this.state.index !== 1}
            id={`simple-tabpanel-1`}
            aria-labelledby={`simple-tab-1`}
          >
            {this.state.index === 1 && (
              <Box sx={{ p: 3 }}>
                <ListDettesJoueurs players={this.props.players} judgments={this.props.judgments}/>
              </Box>
            )}
          </div>
        </SwipeableViews>
      </div>
    );
  }
}

DataTab.propTypes = {
  judgments: PropTypes.array,
  players: PropTypes.array,
}
DataTab.defaultProps = {
  judgments: [],
  players: []
}

export default DataTab;
