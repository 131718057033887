import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import FormAddSanction from './FormAddSanction'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSnackbar } from 'notistack'
import axios from 'axios'

export default function ModalAddSanction(props) {
  
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  
  
  const handleCloseModal = (_, reason) => {
    if (reason !== "backdropClick") {
      props.handleClose();
    }
  }
  
  const handleSubmit = (state, resetState) => {
    setLoading(true);
    const playersIds = [];
    state.playersSelected.forEach(p => {
      playersIds.push(p._id);
    });
    
    if(state.playersSelected.length > 0 && state.sanctionsSelected.length > 0) {
      axios.post(process.env.REACT_APP_API_URL + '/punish',
        {
          players: playersIds,
          sanctions: state.sanctionsSelected,
        })
        .then(async response => {
          if(state.playersSelected.length > 0 && state.playersSelected.length > 0) {
            enqueueSnackbar('Nouvelles sanctions ajoutées !', { variant: 'success', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            enqueueSnackbar('Nouvelle sanction ajoutée !', { variant: 'success', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
          setLoading(false);
          resetState();
          props.refreshJudgments();
        })
        .catch(error => {
          console.error(error);
          enqueueSnackbar('Une erreur s\'esr produite !', {variant: 'error', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          setLoading(false);
        });
    } else {
      enqueueSnackbar('Il faut sélectionner au moins un joueur et une sanction.', {variant: 'error', autoHideDuration: 2000, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
      setLoading(false);
    }
    return false;
  }
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh'
  };
  
  if (props.sanctions.length > 0 && props.players.length > 0) {
    return (
      <Modal
        keepMounted
        open={props.open}
        className={"modal"}
        onClose={handleCloseModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <FormAddSanction handleCloseModal={handleCloseModal}
                           refreshJudgments={props.refreshJudgments}
                           sanctions={props.sanctions}
                           players={props.players}
                           categories={props.categories}
                           loading={loading}
                           handleSubmit={handleSubmit}/>
        </Box>
      </Modal>
    );
  } else {
    return(
      <Dialog
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Erreur"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Aucune sanction et/ou aucun joueur n'est dispônible sur l'application. Impossible donc d'ajouter une sanction !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalAddSanction.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refreshJudgments: PropTypes.func,
  sanctions: PropTypes.array,
  players: PropTypes.array,
  categories: PropTypes.array,
}
ModalAddSanction.defaultProps = {
  open: false,
  handleClose: () => {console.log('handleClose func')},
  refreshJudgments: () => {console.log('refreshJudgments func')},
  sanctions: [],
  players: [],
  categories: []
}

//export default ModalAddSanction;

