import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Euro from '@mui/icons-material/Euro';
import PersonIcon from '@mui/icons-material/Person';
import ListIcon from '@mui/icons-material/List';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Paper from '@mui/material/Paper';

export default function FixedBottomNavigation(props) {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const pageTab = [
    "home",
    "validation",
    "sanctions",
    "players",
  ]
  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            props.changePage(pageTab[newValue]);
          }}
        >
          <BottomNavigationAction label="Cagnotte" icon={<Euro />} />
          <BottomNavigationAction label="Valider les sanctions" icon={<TaskAltIcon />} />
          <BottomNavigationAction label="Gérer les sanctions" icon={<ListIcon />} />
          <BottomNavigationAction label="Gérer les joueurs" icon={<PersonIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
