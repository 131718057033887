import React from 'react';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'

class FormAddSanction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playersSelected: [],
      sanctionsSelected: [],
    };
    this.closeForm = this.closeForm.bind(this);
    this.submit = this.submit.bind(this);
  }
  
  closeForm() {
    this.setState({
      playersSelected: [],
      sanctionsSelected: [],
    })
    this.props.handleCloseModal();
  }
  
  submit(e) {
    e.preventDefault();
    this.props.handleSubmit(this.state, this.closeForm)
  }
  
  render() {
    const sanctionsByCategory = [];
    this.props.categories.forEach(c => {
      const sanctions = this.props.sanctions.filter(s => s.category === c.key);
      if(sanctions.length > 0) {
        sanctionsByCategory.push({
          categoryLabel: c.label,
          sanctions: sanctions.sort((a, b) => (a.label > b.label) - (a.label < b.label))
        });
      }
    })
    
    const sanctionsForm = sanctionsByCategory.map((category, index) => {
      const element = category.sanctions.map((sanction, index) => {
        return (
          <div className={"sanctionItem"} key={"sanctionItem_" + +category.key + "_" + index}>
            <FormControlLabel control={<Checkbox value={sanction._id} checked={this.state.sanctionsSelected.includes(sanction._id)} onChange={ (e) => {
              let newSanctions = [...this.state.sanctionsSelected];
              if(e.target.checked) {
                newSanctions.push(e.target.value)
              } else {
                newSanctions = newSanctions.filter(el => el !== e.target.value);
              }
              this.setState({
                sanctionsSelected: newSanctions
              })
            }}/>} label={sanction.label} />
          </div>
        )
      });
      
      return (
        <div key={"categorySanction" + index} style={{ margin : 0, marginTop: 20, width: '100%'}}>
          <fieldset style={{ width: '90%'}}>
            <legend>{category.categoryLabel}</legend>
            {element}
          </fieldset>
        </div>
      )
    });
    
    return (
      <div>
        <h2>Nouvelle sanction !</h2>
        <Box component="form" className={"formSanction"} onSubmit={this.submit}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={this.props.players}
            getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
            defaultValue={[]}
            filterSelectedOptions
            value={this.state.playersSelected}
            onChange={(e, newValue) => {
              let tab = [];
              newValue.forEach(val => {
                tab.push(val);
              });
              this.setState({
                playersSelected: tab
              });
            }
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Joueurs concernés"
                placeholder="Joueurs concernés"
              />
            )}
          />
          <div style={{ marginTop: 20, marginBottom: 10}}>
            <label><b>Sanctions</b></label>
            <Box component="div" sx={{
              mb: 2,
              display: "flex",
              flexDirection: "column",
              maxHeight: '50vh',
              overflow: "hidden",
              overflowY: "scroll",
            }}>
              <div className={"sanctionsList"}>
                {sanctionsForm}
              </div>
            </Box>
          </div>
          <div className={"buttons"}>
            <Button variant="outlined" endIcon={<CloseIcon />} onClick={this.closeForm} disabled={this.props.loading}>
              Annuler
            </Button>
            <LoadingButton
              loading={this.props.loading}
              variant="contained"
              loadingPosition="end"
              endIcon={<SendIcon />}
              type={'submit'}>
              Soumettre
            </LoadingButton>
          </div>
        </Box>
      </div>
    );
  }
}

FormAddSanction.propTypes = {
  refreshJudgments: PropTypes.func,
  handleCloseModal: PropTypes.func,
  sanctions: PropTypes.array,
  players: PropTypes.array,
  categories: PropTypes.array,
}
FormAddSanction.defaultProps = {
  handleCloseModal: () => {console.log('handleCloseModal func')},
  refreshJudgments: () => {console.log('refreshJudgments func')},
  sanctions: [],
  players: [],
  categories: [],
}

export default FormAddSanction;

