import * as React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Box from '@mui/material/Box';

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  date = new Date(date);
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

class ContentValidationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingValid: false,
      loadingInvalid: false,
      sanctionCliked: null
    }
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.resetLoaders = this.resetLoaders.bind(this);
  }
  
  handleButtonClick = (row, status) => {
    this.setState({
      loadingValid: status === 'valid',
      loadingInvalid: status === 'invalid',
      sanctionCliked: row
    })
    this.props.doAction(row, status, this.resetLoaders);
  }
  
  resetLoaders = () => {
    this.setState({
      loadingValid: false,
      loadingInvalid: false,
      sanctionCliked: null
    })
  }
  
  render() {
    const toValidateJudgment = [...this.props.judgments].filter(e => e.status === 'to_validate');
    const rows = [...toValidateJudgment].sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    
    const content = rows.length > 0 ? (
      rows.map((row, index) => (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={"rowTable" + index}>
          <TableCell>
            {row.player.first_name} {row.player.last_name}
          </TableCell>
          <TableCell>
            {row.sanction.label}
          </TableCell>
          <TableCell>
            {row.sanction.price.toFixed(2)} €
          </TableCell>
          <TableCell>
            {formatDate(row.date)}
          </TableCell>
          <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ m: 1, position: 'relative' }}>
                <IconButton aria-label="valider" color="success" onClick={() => this.handleButtonClick(row, 'valid')} disabled={this.state.loadingValid || this.state.loadingInvalid}>
                  <DoneIcon />
                </IconButton>
                {this.state.loadingValid && this.state.sanctionCliked !== null &&  this.state.sanctionCliked._id === row._id && (
                  <CircularProgress
                    size={38}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: 1,
                      left: 1,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <IconButton aria-label="invalider" color="error" onClick={() => this.handleButtonClick(row, 'invalid')} disabled={this.state.loadingValid || this.state.loadingInvalid}>
                  <CloseIcon />
                </IconButton>
                {this.state.loadingInvalid && this.state.sanctionCliked !== null &&  this.state.sanctionCliked._id === row._id && (
                  <CircularProgress
                    size={38}
                    sx={{
                      color: red[500],
                      position: 'absolute',
                      top: 1,
                      left: 1,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          Aucune sanction à valider pour le moment
        </TableCell>
      </TableRow>
    );
    return (
      <div style={{
        width: '100%',
        marginBottom: 30
      }}>
        <h2 style={{ textAlign: 'center' }}>Validation des sanctions</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Joueur</TableCell>
                <TableCell>Sanction</TableCell>
                <TableCell>Montant</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

ContentValidationPage.propTypes = {
  judgments: PropTypes.array,
  doAction: PropTypes.func,
}
ContentValidationPage.defaultProps = {
  judgments: [],
  doAction: () => console.log("doAction func"),
}

export default ContentValidationPage;
