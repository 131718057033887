import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Modal from '@mui/material/Modal'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import { getAuthToken } from '../utils/auth'
import LoadingButton from '@mui/lab/LoadingButton'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar'

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerSelected : null,
      formVisible : false,
      first_name : "",
      last_name : "",
      error: false,
      authError: false,
      loading: false,
      confirmOpen: false,
    }
    this.openModalEmpty = this.openModalEmpty.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.deletePlayer = this.deletePlayer.bind(this);
    this.openModalWithPlayer = this.openModalWithPlayer.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
    this.dataTreatment = this.dataTreatment.bind(this);
  
  }
  
  openModalEmpty = () => {
    this.setState({
      playerSelected : null,
      formVisible : true,
      first_name : "",
      last_name : "",
      error: false,
      authError: false,
      loading: false,
      confirmOpen: false,
    })
  }
  
  closeModal = () => {
    this.setState({
      playerSelected : null,
      formVisible : false,
      error: false,
      authError: false,
      loading: false,
      first_name : "",
      last_name : "",
      confirmOpen: false,
    })
  }
  
  openDeleteDialog = (player) => {
    this.setState({
      playerSelected: player,
      confirmOpen: true,
    })
  }
  
  closeDeleteDialog = () => {
    this.setState({
      playerSelected: null,
      confirmOpen: false,
    })
  }
  
  deletePlayer = () => {
    this.setState({
      loading: true,
      error: false,
      authError: false,
    })
    axios.delete(process.env.REACT_APP_API_URL + '/player/' + this.state.playerSelected._id,
      { headers: { "Authorization": getAuthToken() } })
      .then(async response => {
        this.setState({
          loading: false
        })
        this.closeDeleteDialog();
        this.props.refreshPlayers();
      })
      .catch(error => {
        console.error(error);
        this.setState({
          loading: false,
          error: error.response.status !== 401,
          authError: error.response.status === 401,
        })
      });
  }
  
  openModalWithPlayer = (player) => {
    this.setState({
      playerSelected : player,
      formVisible : true,
      error: false,
      authError: false,
      loading: false,
      first_name : player.first_name,
      last_name : player.last_name,
      confirmOpen: false,
    })
  }
  
  handleSubmitForm = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      error: false,
      authError: false
    });
    
    if(this.state.first_name === null || this.state.first_name.trim() === "" || this.state.last_name === null || this.state.last_name.trim() === "") {
      this.setState({
        loading: false,
        error: true
      });
    } else {
      if(this.state.playerSelected) {
        axios.put(process.env.REACT_APP_API_URL + '/player/' + this.state.playerSelected._id,
          {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
          }, { headers: { "Authorization": getAuthToken() } })
          .then(async response => {
            let player = await response.data
            this.dataTreatment(player);
          })
          .catch(error => {
            console.error(error);
            this.setState({
              loading: false,
              error: error.response.status !== 401,
              authError: error.response.status === 401,
            })
          });
      } else {
        axios.post(process.env.REACT_APP_API_URL + '/player',
          {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
          }, { headers: { "Authorization": getAuthToken() } })
          .then(async response => {
            let player = await response.data
            this.dataTreatment(player);
          })
          .catch(error => {
            console.error(error);
            this.setState({
              loading: false,
              error: error.response.status !== 401,
              authError: error.response.status === 401,
            })
          });
      }
    }
  };
  
  dataTreatment = (player) => {
    this.setState({
      loading: false,
      error: false,
      authError: false,
      confirmOpen: false,
    })
    this.props.updatePlayers(player);
    this.closeModal();
  }
  
  render() {
    const rows = [...this.props.players].sort((a, b) => {
      const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
      const nameA2 = a.last_name.toUpperCase(); // ignore upper and lowercase
      const nameB2 = b.last_name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      if (nameA2 < nameB2) {
        return -1;
      }
      if (nameA2 > nameB2) {
        return 1;
      }
      return 0;
    });
    const content = rows.length > 0 ? (
      rows.map((row, index) => (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={"rowTableUsers" + index}>
            <TableCell>
              {row.first_name}
            </TableCell>
            <TableCell>
              {row.last_name}
            </TableCell>
            <TableCell>
              <IconButton aria-label="edit" onClick={() => this.openModalWithPlayer(row)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" color="error" onClick={() => this.openDeleteDialog(row)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))
    ) : (
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          Aucun joueur pour le moment
        </TableCell>
      </TableRow>
    );
    const modalTitle = this.state.playerSelected ? "Modification du joueur" : "Ajouter un joueur";
    const errorMsg = this.state.error ? "Tous les champs sont obligatoires" : (this.state.authError ? "Le token d'authentification a expiré. Veuillez vous reconnecter." : "");
  
    const styleModal = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
    return (
      <div style={{
        width: '100%',
        marginBottom: 30
      }}>
        <h2 style={{ textAlign: 'center' }}>Gestion des joueurs</h2>
        <Button variant={"contained"} style={{ textAlign: 'right', margin: '10px auto 10px auto', float: 'right'}} onClick={this.openModalEmpty} startIcon={<AddIcon />}>Ajouter un joueur</Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Prénom</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {content}
            </TableBody>
          </Table>
        </TableContainer>
        <Modal
          className={"formModal modal"}
          keepMounted
          open={this.state.formVisible}
          onClose={this.closeModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={styleModal}
          >
            <Typography component="h1" variant="h5">
              {modalTitle}
            </Typography>
            <Box component="form" onSubmit={this.handleSubmitForm} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="first_name"
                label="Prénom"
                name="first_name"
                autoFocus
                value={this.state.first_name}
                onChange={e => this.setState({first_name: e.target.value})}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="last_name"
                label="Nom"
                id="last_name"
                value={this.state.last_name}
                onChange={e => this.setState({last_name: e.target.value})}
              />
              <div className={"buttons"}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={this.closeModal}
                  sx={{ mt: 3, mb: 2 }}
                  disabled={this.state.loading}
                >
                  Fermer
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={this.state.loading}
                >
                  Valider
                </LoadingButton>
              </div>
            </Box>
          </Box>
        </Modal>
        <Dialog
          open={this.state.confirmOpen}
          onClose={this.closeDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation de la suppression"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              La suppression de ce joueur entrainera la suppression de toutes les sanctions liées au joueur. Êtes -vous certain de vouloir le supprimer ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDeleteDialog} disabled={this.state.loading}>Annuler</Button>
            <LoadingButton
              loading={this.state.loading}
              onClick={this.deletePlayer}
              autoFocus
            >
              Supprimer
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Snackbar open={this.state.error || this.state.authError} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => {
          if(this.state.authError) {
            this.setState({ error: false, authError: false })
            this.props.logout();
          } else {
            this.setState({ error: false, authError: false })
          }
        }}>
          <Alert severity="error">{errorMsg}</Alert>
        </Snackbar>
      </div>
    );
  }
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

UsersPage.propTypes = {
  players: PropTypes.array,
  updatePlayers: PropTypes.func,
  refreshPlayers: PropTypes.func,
  logout: PropTypes.func
}
UsersPage.defaultProps = {
  players: [],
  updatePlayers:  () => console.log("UpdatePlayers func"),
  refreshPlayers:  () => console.log("refreshPlayers func"),
  logout:  () => console.log("logout func")
}

export default UsersPage;
